import React from 'react';
import { Document, BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';

interface Images {
    contentful_id: string;
    gatsbyImageData: ImageDataLike;
    title: string;
}

const options = (images: Images[]) => {

    return {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                return <a href={node.data.uri} target="_blank" rel="noreferrer noopener">{children}</a>
            },
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const imageId = node.data.target.sys.id; // we get image id in markdown
                // find image wiht that id in array of gatsby images
                const gatsbyImage = images.find(image => image.contentful_id === imageId);
                const image = getImage(gatsbyImage.gatsbyImageData);
                return <div className="single__image"><GatsbyImage image={image} alt={gatsbyImage.title} /></div>
            }
        }
    }
};

interface Props {
    document: Document;
    images: Images[];
}

const RichTextRender: React.FC<Props> = ({ document, images }) => {
    return <React.Fragment>{documentToReactComponents(document, options(images))}</React.Fragment>
};

export default RichTextRender;