import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../Layout';
import SEO from '../Seo';
import RichTextRender from '../RichTextRender';
import More from './More';

import { ArticleInterface } from '../../shared/Interfaces';

import useDate from '../../hooks/useDate';

interface Props {
    contentfulVest: ArticleInterface;
}

const Single: React.FC<PageProps<Props>> = props => {

    const post: ArticleInterface = props.data.contentfulVest;

    const { naslov, tekst, slika, autor, updatedAt } = post;

    const image = getImage(slika);

    const date = useDate(updatedAt);

    return (
        <Layout>
            <SEO title={naslov} />
            <div className="single__container">
                <h1 className="single__title">
                    {naslov}
                </h1>
                <div className="single__thumbnail">
                    <GatsbyImage image={image} alt={naslov} />
                </div>
                <div className="single__description">
                    <div className="single__author">
                        <h4>
                            Autor: {autor}
                        </h4>
                    </div>
                    <div className="single__date">
                        {date}
                    </div>
                </div>
                <RichTextRender document={JSON.parse(tekst.raw)} images={tekst.references} />
            </div>
            <More slug={post.slug} />
        </Layout>
    );
};

export const query = graphql`
query SingleQuery($slug: String) {
    contentfulVest(slug: {eq: $slug}) {
      slug
      naslov
      autor
      updatedAt
      slika {
        gatsbyImageData(width: 750, aspectRatio: 1.33, resizingBehavior: THUMB, quality: 100)
        updatedAt
      }
      tekst {
        raw
        references {
            ... on ContentfulAsset {
                gatsbyImageData(width: 500, resizingBehavior: THUMB)
               contentful_id
               title
               file {
                  url
               }
            }
         }
      }
    }
  }  
`;

export default Single;