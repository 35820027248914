import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Article, { ArticleContainer } from '../Article';

import { ArticleInterface } from '../../shared/Interfaces';

const query = graphql`
query SingleMore {
    allContentfulVest(limit: 50) {
      nodes {
        naslov
        kategorija
        slug
        updatedAt
        slika {
          gatsbyImageData(
                layout: CONSTRAINED
                width: 355, 
                aspectRatio: 1.5 
                resizingBehavior: THUMB
            )
        }
      }
    }
  }
`;

const getRandomNumber = () => Math.floor(Math.random() * 50);

interface Props {
    slug: string;
}

const More: React.FC<Props> = ({ slug }) => { // here we will display 3 random posts from last 50

    const data = useStaticQuery(query);
    const nodes: ArticleInterface[] = data.allContentfulVest.nodes;

    const [ posts, setPosts ] = useState<ArticleInterface[]>([]);

    useEffect(() => {

        const numbers = [];
        while(numbers.length < 3) {
            const number = getRandomNumber();
            const find = numbers.find(num => num === number);
            if(!find) { // first check if its already generated number
                if((nodes[number])) { // check if posts exist, not needed when there are lot of posts
                    // check if its already there
                    if(nodes[number].slug !== slug) numbers.push(number);
                } 
            }
        }

        const posts = [];
        numbers.forEach(number => {
            posts.push(nodes[number]);
        });
        setPosts(posts);
    }, [ nodes ]);

    return (
        <div className="article__more">
            <h5 className="article__more--heading">
                Izdvajamo
            </h5>
            <ArticleContainer type="triple">
                {posts.map(post => <Article key={post.slug} {...post} type="triple" /> )}
            </ArticleContainer>
        </div>
    );
};

export default More;